import React from 'react';
import { Box, Text } from '@chakra-ui/core';
import { graphql } from 'gatsby';
import EmbedContainer from 'react-oembed-container';

import Layout from '../../components/Layout';
import HeaderImage from '../../components/HeaderImage';

const BlogPost = ({ data }) => {
  return (
    <Layout>
      <Box>
        <HeaderImage
          imageData={
            data.wordpressPost.acf.header_image.localFile.childImageSharp.fluid
          }
          className="dimmed"
        >
          <Text
            textAlign="center"
            fontSize="6xl"
            font-weight="400"
            color="white"
          >
            {data.wordpressPost.title}
          </Text>
        </HeaderImage>
        <EmbedContainer markup={data.wordpressPost.content}>
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            width="100%"
            mb={6}
            dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}
          />
        </EmbedContainer>
      </Box>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query PostQuery($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      content
      title
      slug
      acf {
        header_image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
